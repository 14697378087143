<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" service="/sys/sysWorkers" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  props: ["currNode", "selectRow"],
  data() {
    return {
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            field: 'name',
            title: '职工名称',
            minWidth: 160,
            editRender: {
              name: 'input'
            }
          },
          {
            field: "positionName",
            title: "所属岗位",
            minWidth: 120,
            editRender: {
              name: "$input",
            },
          },
          {
            field: "phone",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              name: "input"
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 100,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                status: "primary",
                label: "选择",
                eventName: "customEvent",
                events: {
                  click: (row) => {
                    this.selectEvent(row);
                  }
                }
              }]
            },
          },
        ],
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {
          organizationId: [
            { required: true, message: "请选择所属上级" },
          ],
          name: [
            { required: true, message: "请输入职工名称" },
          ],
          positionId: [
            { required: true, message: "请选择所属岗位" },
          ],
          sex: [
            { required: true, message: "请选择性别" },
          ],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          name: "",
          code: "",
          sex: "1",
          type: "1",
          positionId: "",
          orderCode: "0",
          enabled: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "职工姓名",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入职工姓名",
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          enabled: "1",
        },
      },
    };
  },
  created() {
    let { data } = this.searchOptions;
    data["organizationId"] = this.currNode.parentId
  },

  methods: {
    selectEvent(row) {
      this.$confirm("是否选择当前职工!", this.$t("confirmTitle")).then((type) => {
        if (type === "confirm") {
          this.$parent.$parent.sendSingleEvent(row);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
