<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/community" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="指派处理人" width="800" height="500" show-zoom>
    <template v-slot>
      <SelectRepairMaster :currNode="currNode" :selectRow="selectRow" />
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from "vuex";
import SelectRepairMaster from "./SelectRepairMaster";
export default {
  components: {
    SelectRepairMaster,
  },
  data() {
    return {
      currNode: {},
      loading: false,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "电话投诉",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == "1001") {
                    let { data } = this.formOptions;
                    data["parentId"] = this.currNode.id;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error("请选择小区");
                  }
                },
              },
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "roomName",
            title: "房屋编号",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号",
              },
            },
          },
          {
            field: "complaintUsername",
            title: "投诉人",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入投诉人",
              },
            },
          },
          {
            field: "phone",
            title: "联系方式",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入联系方式",
              },
            },
          },
          {
            field: "type",
            title: "投诉类型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "complaintType",
              props: {
                maxlength: 24,
                placeholder: "请输入投诉类型",
              },
            },
          },
          {
            field: "createTime",
            title: "投诉时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请选择投诉时间",
              },
            },
          },
          {
            field: "returnVisit",
            title: "是否回访",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                maxlength: 24,
                placeholder: "请选择是否回访",
              },
            },
          },
          {
            field: "handlerUsername",
            title: "处理人",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入处理人",
              },
            },
          },
          {
            field: "handlerPhone",
            title: "处理人电话",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入处理人电话",
              },
            },
          },
          {
            field: "status",
            title: "处理状态",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "complainStatus",
              props: {
                maxlength: 24,
                placeholder: "请选择处理状态",
              },
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 220,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "assignHandler",
                  status: "primary",
                  label: "指派处理人",
                  params: {
                    property: "status",
                    value: ["10", "20"],
                  },
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    },
                  },
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                  params: {
                    property: "status",
                    value: ["10"],
                  },
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        exportConfig: {},
        importConfig: {},
      },
      formOption: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "handlerUsername",
            title: "处理人",
            span: 12,
            itemRender: {
              name: "$VxeSelectWorker",
              props: {
                suffixIcon: "fa fa-search",
                placeholder: "请选择处理人",
              },
              events: {
                click: (params, row) => {
                  let { data, property } = params;
                  data[property] = row.name;
                  data["handlerUserId"] = row.id;
                  data["handlerPhone"] = row.phone;
                },
              },
            },
          },
          {
            field: "handlerPhone",
            title: "联系方式",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入联系方式",
              },
            },
          },
          {
            field: "remark",
            title: "备注",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入备注信息",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          handlerUsername: [{
            required: true,
            message: "请选择处理人",
          }, ],
        },
        // 新增功能字段初始化
        data: {
          handlerUsername: "",
          handlerUserId: "",
          handlerPhone: "",
        },
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 110,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == "1001") {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error("请选择所属小区");
                  }
                },
              },
            },
          },
          {
            field: "roomName",
            title: "房间号",
            span: 12,
            itemRender: {
              name: "$VxeSelectRoom",
              defaultProps: {
                label: "name",
                value: "id",
              },
              props: {
                maxlength: 24,
                placeholder: "请选择房间号",
              },
              events: {
                click: (params, row) => {
                  let { data, property } = params;
                  data[property] = row.name;
                  data["complaintUserId"] = row.id;
                  data["complaintUsername"] = row.ownerName;
                  data["phone"] = row.ownerLink;
                },
              },
            },
          },
          {
            field: "complaintUsername",
            title: "投诉人",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请选择投诉人",
              },
            },
          },
          {
            field: "phone",
            title: "联系方式",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入联系方式",
              },
            },
          },
          {
            field: "type",
            title: "投诉类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "complaintType",
              props: {
                maxlength: 24,
                placeholder: "请输入投诉类型",
              },
            },
          },
          {
            field: "returnVisit",
            title: "是否回访",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                maxlength: 24,
                placeholder: "请选择是否回访",
              },
            },
          },
          {
            field: "createTime",
            title: "投诉时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                maxlength: 24,
                placeholder: "请选择投诉时间",
              },
            },
          },
          {
            field: "content",
            title: "投诉内容",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入投诉内容",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{
            required: true,
            message: "请选择所属小区",
          }, ],
          type: [{
            required: true,
            message: "请输入投诉类型",
          }, ],
          roomName: [{ required: true, message: "请选择房间号" }],
          complaintUsername: [{
            required: true,
            message: "请选择投诉人",
          }, ],
          phone: [{
            required: true,
            message: "请输入投诉人联系方式",
          }, ],
          returnVisit: [{ required: true, message: "请选择是否回访" }],
          content: [{
            required: true,
            message: "请输入投诉内容",
          }, ],
        },
        // 新增功能字段初始化
        data: {
          roomId: "",
          roomName: "",
          complaintUsername: "",
          complaintUserId: "",
          phone: "",
          type: "1",
          returnVisit: "0",
          content: "",
          status: "0",
          parentId: "",
          createTime: this.$XEUtils.toDateString(
            new Date(),
            "yyyy-MM-dd HH:mm:ss"
          ),
          handlerPhone: "",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "roomName",
            title: "房间号",
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请选择房间号",
              },
            },
          },
          {
            field: "complaintUsername",
            title: "投诉人",
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入投诉人",
              },
            },
          },
          {
            field: "type",
            title: "投诉类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "complaintType",
              props: {
                maxlength: 24,
                placeholder: "请选择投诉类型",
              },
            },
          },
          {
            field: "status",
            title: "处理状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "complainStatus",
              props: {
                placeholder: "请选择处理状态",
              },
            },
          },
          {
            field: "phone",
            title: "联系方式",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入联系方式",
              },
            },
          },
          {
            field: "handlerUsername",
            title: "处理人",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入处理人",
              },
            },
          },
          {
            field: "returnVisit",
            title: "是否回访",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                maxlength: 24,
                placeholder: "请选择是否回访",
              },
            },
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          roomName: "",
          complaintUsername: "",
          phone: "",
          type: "",
          returnVisit: "",
          status: "",
        },
      },
    };
  },

  methods: {
    ...mapActions(["submitEventService"]),

    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(
        this.gridOptions.columns,
        treeData,
        "parentId"
      );
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data["parentId"] = node.id;
      this.formOptions.data["communityName"] = node.label;
      this.searchOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },

    // 指派处理人
    sendSingleEvent(row) {
      let params = {
        businessId: this.selectRow.id,
        handlerUserId: row.id,
        handlerUsername: row.name,
        positionName: row.positionName,
        handlerPhone: row.phone,
      };
      this.submitEventService({
        service: "/wisdom/complaint/assign",
        params: params,
      }).then(({ code }) => {
        if (code == 200) {
          this.$refs.xModal.close();
          this.$refs.xGrid.getLists();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
